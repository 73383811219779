<template>
  <ul class="gallery" :class="reversedList.length === 1 ? 'flex' : ''">
    <li
      class="box text-white"
      v-for="(item, i) in reversedList"
      :key="i"
      :style="reversedList.length === 1 ? sizeOneCards.li : ''"
    >
      <slot :items="item"></slot>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      listRepos: [
        "0049-Otto-Klauss",
        "proyecto-MEVN-front-end",
        "-proyecto-MEVN-back-end",
        "player-music",
        "api-social-auth-passportjs-mern",
        "tasks-projects",
      ],
      sizeOneCards: {
        li: {
          maxWidth: "600px",
        },
        /* flex: {
          display: "flex",
          justifyontent: "center",
        }, */
      },
    };
  },
  computed: {
    ...mapState("getDataRepos", ["repos"]),
    ...mapGetters("getDataRepos", ["filterByCategory"]),
    /* sizeOneCards(){
      return [ ]
    } */

    reversedList() {
      return this.filterByCategory.slice().reverse();
    },
  },
  methods: {
    ...mapActions("getDataRepos", ["getDataRepos"]),
    principalRepos() {
      this.listRepos.forEach((ele) => {
        this.getDataRepos(ele);
      });
    },
  },
  created() {
    if (this.repos.length === 0) {
      this.principalRepos();
      this.repos;
      this.filterByCategory;
      //console.log(this.filterByCategory)
    }
    // this.getListFinal;
    // console.log(this.repos.img);
  },
};
</script>

<style></style>
